<template>
  <b-row>
    <b-col sm="3">
      <!-- overview -->
      <div class="card shadow-sm">
        <div class="card-body" style="padding: 0">
          <ul class="list-group list-group-flush">
            <li class="list-group-item active">Overview</li>
          </ul>
        </div>
      </div>

      <!-- domain status -->
      <RWCard title="Status" class="my-3">
        <template v-slot:body>
          <div v-if="loading.status">
            <v-skeleton-loader type="text@6"></v-skeleton-loader>
          </div>
          <div v-else>
            <p>
              <b>Status</b> :
              <span
                class="font-weight-bold"
                :class="mx_domain_color(status.status)"
                >{{ status.status }}</span
              >
              <br />
              Package : {{ status.name }} <br />
              Due Date : {{ status.duedate }} <br />
              Billing Cycle : {{ status.recurring }} <br />
              Price : {{ status.price }}
            </p>
          </div>
        </template>
      </RWCard>

      <!-- guide -->
      <RWWidgetGuides
        :loading="loading.guide"
        :data="xhr.guide"
        :success="success.guide"
      ></RWWidgetGuides>

      <!-- help -->
      <RWWidgetBantuan></RWWidgetBantuan>
    </b-col>
    <b-col sm="9">
      <!-- alert -->
      <div v-if="!loading.servicestatus">
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
          v-if="xhr.servicestatus.backup && xhr.avertiz !== 'enabled'"
        >
          <strong><i class="fa fa-bell pr-1"></i></strong> Backup website
          disabled.
          <!-- Detail » -->
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <b-row>
        <!-- informasi akun -->
        <b-col sm="12">
          <RWCard title="Informasi Akun">
            <template v-slot:body>
              <div class="row">
                <div class="col-sm-6">
                  <div v-if="loading.account">
                    <v-skeleton-loader
                      type="sentences@3,text"
                    ></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <div v-if="success.account">
                      <div v-if="xhr.account.post.username">
                        <strong class="font-weight-bold">Username : </strong>
                        {{ xhr.account.post.username }}<br />

                        <strong class="font-weight-bold">Password : </strong>
                        <span v-if="showPassword">{{ decode_password }}</span>
                        <span v-else>{{
                          password_sensor(decode_password)
                        }}</span>
                        <a
                          class="ml-2"
                          href="javascript:void(0)"
                          @click="showPassword = !showPassword"
                          title="Show / Hide Password"
                          v-b-tooltip.hover
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                        &nbsp;
                        <a
                          href="javascript:void(0)"
                          title="Copy Password"
                          v-b-tooltip.hover
                          @click="copy_password"
                        >
                          <i class="fa fa-copy"></i>
                        </a>
                      </div>

                      <strong class="font-weight-bold">Manage Domain : </strong>
                      <b-link
                        :to="{
                          path: `/product/domain/${xhr.account.id}?ref=hosting&domain=${status.domain}`
                        }"
                        >Disini</b-link
                      ><br />

                      <strong class="font-weight-bold">NameServer : </strong>
                      <ul class="pl-5">
                        <li
                          v-for="(ns, index) in xhr.account.nameserver"
                          :key="index"
                        >
                          {{ ns }}
                        </li>
                      </ul>

                      <!-- server / service status -->
                      <div v-if="loading.servicestatus">
                        <v-skeleton-loader
                          type="sentences@2"
                          style="margin: 0 auto"
                        ></v-skeleton-loader>
                      </div>
                      <b-row
                        v-if="
                          !loading.servicestatus && status.module === 'rwpanel'
                        "
                      >
                        <b-col sm="4" cols="4">
                          <strong class="font-weight-bold">Space : </strong>
                          <br />
                          {{ xhr.servicestatus.diskusage }}
                        </b-col>
                        <b-col sm="4" cols="4">
                          <strong class="font-weight-bold">Backup : </strong>
                          <br />{{ xhr.servicestatus.backup }}
                          <b-link
                            :to="{
                              name: 'hosting-upgrade',
                              params: { id: status.id, addons: 'backup' }
                            }"
                            v-if="!xhr.servicestatus.backup"
                          >
                            (Beli)</b-link
                          >
                        </b-col>
                        <b-col sm="4" cols="4">
                          <strong class="font-weight-bold"
                            >Accelerator :
                          </strong>
                          <br />{{ xhr.servicestatus.accelerator }}
                        </b-col>
                      </b-row>
                    </div>
                    <div v-else>
                      <b-alert show variant="danger">{{ xhr.account }}</b-alert>
                    </div>
                  </div>

                  <b-row class="my-3">
                    <b-col sm="6" cols="6">
                      <div v-if="loading.account">
                        <v-skeleton-loader type="button"></v-skeleton-loader>
                      </div>
                      <div v-else>
                        <div v-if="success.account">
                          <b-link
                            :to="login_control_panel(status.module)"
                            target="_blank"
                            class="btn btn-primary btn-block"
                          >
                            Login Panel
                          </b-link>
                        </div>
                        <div v-else>
                          <b-button variant="danger">{{
                            xhr.account
                          }}</b-button>
                        </div>
                      </div>
                    </b-col>
                    <b-col sm="6" cols="6">
                      <div v-if="loading.account">
                        <v-skeleton-loader type="button"></v-skeleton-loader>
                      </div>
                      <div v-else>
                        <b-button
                          v-b-modal.modalChangePassword
                          class="btn btn-warning btn-block"
                          >Change Password</b-button
                        >
                      </div>
                    </b-col>
                  </b-row>

                  <!-- hr & tips jika rwpanel -->
                  <hr v-if="status.module === 'rwpanel'" />
                  <p
                    v-html="mx_config.hosting.tips"
                    v-if="status.module === 'rwpanel'"
                  ></p>
                </div>

                <!-- hosting APP -->
                <div class="col-sm-6" id="rw-style-hostingapp">
                  <hr class="d-block d-sm-none" />

                  <!-- loading hosting app ma'mum ke servicestatus ada pengecekan addons -->
                  <div v-if="loading.servicestatus">
                    <div class="row">
                      <div class="col-4">
                        <b-skeleton-img></b-skeleton-img>
                      </div>
                      <div class="col-4">
                        <b-skeleton-img></b-skeleton-img>
                      </div>
                      <div class="col-4">
                        <b-skeleton-img></b-skeleton-img>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <!-- if module rwpanel -->
                    <div class="row" v-if="status.module === 'rwpanel'">
                      <!-- yang pasti tampil jika module rwpanel -->
                      <template
                        v-for="(row, key) in mx_config.hosting.app.data.rwpanel"
                      >
                        <b-col sm="4" cols="4" :key="key">
                          <b-link
                            :to="row.link.path + status.id"
                            target="_blank"
                          >
                            <div class="text-center p-2">
                              <i :class="row.icon" class="fa-2x"></i>
                              <br />
                              <p class="text-dark">{{ row.title }}</p>
                            </div>
                          </b-link>
                        </b-col>
                      </template>

                      <!-- tampil optional tergantung addons status -->
                      <template
                        v-for="(addon, index) in mx_config.hosting.app.data
                          .addons"
                      >
                        <!-- backup -->
                        <b-col
                          sm="4"
                          cols="4"
                          :key="index + 10"
                          v-if="
                            addon.id === 'rwbackup' && xhr.servicestatus.backup
                          "
                        >
                          <b-link
                            :to="addon.link.path + status.id"
                            target="_blank"
                          >
                            <div class="text-center p-2">
                              <i :class="addon.icon" class="fa-2x"></i>
                              <br />
                              <p class="text-dark">{{ addon.title }}</p>
                            </div>
                          </b-link>
                        </b-col>

                        <!-- sitepro -->
                        <b-col
                          sm="4"
                          cols="4"
                          :key="index + 10"
                          v-if="
                            addon.id === 'rwweebly' && xhr.servicestatus.weebly
                          "
                        >
                          <b-link
                            :to="addon.link.path + status.id"
                            target="_blank"
                          >
                            <div class="text-center p-2">
                              <i :class="addon.icon" class="fa-2x"></i>
                              <br />
                              <p class="text-dark">{{ addon.title }}</p>
                            </div>
                          </b-link>
                        </b-col>

                        <!-- weebly -->
                        <b-col
                          sm="4"
                          cols="4"
                          :key="index + 10"
                          v-if="
                            addon.id === 'rwsitepro' &&
                              xhr.servicestatus.sitepro
                          "
                        >
                          <b-link
                            :to="addon.link.path + status.id"
                            target="_blank"
                          >
                            <div class="text-center p-2">
                              <i :class="addon.icon" class="fa-2x"></i>
                              <br />
                              <p class="text-dark">{{ addon.title }}</p>
                            </div>
                          </b-link>
                        </b-col>
                      </template>
                    </div>

                    <!-- if module plesk -->
                    <div v-else-if="status.module === 'plesk'">
                      <p v-html="mx_config.hosting.tips"></p>
                    </div>

                    <!-- if module !=plesk || != rwpanel -->
                    <div v-else>
                      <b-alert show variant="danger">Module not found!</b-alert>
                    </div>
                  </div>
                </div>
                <!-- end hosting App -->
              </div>
            </template>
          </RWCard>
        </b-col>

        <b-col sm="12" class="my-3">
          <div class="row" v-if="loading.upsell">
            <div class="col-sm-4">
              <b-skeleton-img></b-skeleton-img>
            </div>
            <div class="col-sm-4">
              <b-skeleton-img></b-skeleton-img>
            </div>
            <div class="col-sm-4">
              <b-skeleton-img></b-skeleton-img>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-sm-4">
              <!-- cloud hosting -->
              <div
                class="card my-3"
                style="background-color: #47ccff; color: white"
              >
                <div class="card-body text-center">
                  <h4>Upgrade ke Cloud Hosting</h4>
                  <p class="mt-1">
                    Cloud Hosting adalah solusi hosting untuk website dan
                    aplikasi dengan load tinggi seperti e-learning, sistem
                    informasi, portal berita, dll.
                  </p>
                  <a
                    :href="xhr.upsell.cloud.link"
                    class="btn btn-outline-light"
                    style="border-radius: 72px"
                    target="_blank"
                    >Pelajari</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <!-- space email -->
              <div
                class="card my-3"
                style="background-color: #1fe0ba; color: white"
              >
                <div class="card-body text-center">
                  <h4>Butuh Space Lebih Besar untuk Email?</h4>
                  <p class="my-4">
                    Tambahkan layanan E-mail Bisnis, solusi email berkapasitas
                    besar hingga 30GB dengan hanya Rp. 14.900/bulan.
                  </p>
                  <a
                    :href="xhr.upsell.space.link"
                    class="btn btn-outline-light"
                    style="border-radius: 72px"
                    target="_blank"
                    >Pelajari</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <!-- website builder email -->
              <div
                class="card my-3"
                style="background-color: #ff47cc; color: white"
              >
                <div class="card-body text-center">
                  <h4>Buat website instalasi mudah?</h4>
                  <p class="my-4">
                    Buat website dengan sangat mudah menggunakan website builder
                    sekarang juga!
                  </p>
                  <a
                    :href="xhr.upsell.builder.link"
                    class="btn btn-outline-light mt-4"
                    style="border-radius: 72px"
                    target="_blank"
                    >BUAT WEBSITE</a
                  >
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- modal change password -->
    <b-modal id="modalChangePassword" hide-footer title="Change Password">
      <form action="" @submit="submitChangePassword">
        <div class="form-group">
          <label>Password:</label>
          <input
            type="password"
            v-model="form.changePassword.password_new"
            class="form-control"
            placeholder="Enter new password"
            required=""
          />
        </div>
        <div class="form-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            v-model="form.changePassword.password_confirm"
            class="form-control"
            placeholder="Enter confirm new password"
            required=""
          />
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </b-modal>
    <!-- end modal -->
  </b-row>
</template>

<script>
import copy from "clipboard-copy";
import RWCard from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import RWWidgetGuides from "@/views/partials/widgets/rw/widgetGuides";
import RWWidgetBantuan from "@/views/partials/widgets/rw/widgetBantuan.vue";
import {
  productHostingMixin,
  productMixin
} from "@/common/mixins/product/product-mixin.js";
import {
  ApiURL,
  breadcrumb,
  httpCode,
  loading,
  notif,
  util
} from "@/common/mixins/general.js";

export default {
  name: "productHostingSingleActive",
  mixins: [
    breadcrumb,
    httpCode,
    productMixin,
    loading,
    ApiURL,
    productHostingMixin,
    notif,
    util
  ],
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  components: {
    RWCard,
    RWWidgetBantuan,
    RWWidgetGuides
  },
  data() {
    return {
      showPassword: false,
      loading: {
        account: true,
        todolist: true,
        servicestatus: true,
        cpanel: true,
        upsell: true,
        guide: true
      },
      xhr: {
        todolist: null,
        account: null,
        status: null,
        cpanel: null,
        upsell: null,
        guide: [],
        avertiz: "",
        servicestatus: null
      },

      /**
       * -----------------------------------------
       * tampung jika request  == 200 / != 200
       * -----------------------------------------
       */
      success: {
        todolist: 200,
        account: true,
        cpanel: true,
        guide: false
      },

      /**
       * -----------------------------------------
       * form tampung data perubahan
       * -----------------------------------------
       */
      form: {
        changePassword: {
          password_new: null,
          password_confirm: null
        }
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * get account hosting
     * -----------------------------------------
     */
    get_account() {
      this.xhr_hosting("account", this.status.id)
        .then(({ data }) => {
          this.xhr.account = data.data.account;
        })
        .catch(({ response }) => {
          this.xhr.account = response.data.message;
          this.success.account = false;
        })
        .finally(() => {
          this.loading.account = false;
        });
    },

    /**
     * -----------------------------------------
     * get service status server hosting
     * -----------------------------------------
     */
    get_servicestatus() {
      this.xhr_hosting("servicestatus", this.status.id)
        .then(({ data }) => {
          this.xhr.servicestatus = data.data.account;
          if (data.data.account.backup) {
            this.get_status_avertiz();
          }
        })
        .catch(({ response }) => {
          this.xhr.servicestatus = response.message;
        })
        .finally(() => {
          this.loading.servicestatus = false;
        });
    },

    /**
     * -----------------------------------------
     * get guides
     * -----------------------------------------
     */
    get_guide() {
      this.http
        .get(this.end.general.guide)
        .then(({ data }) => {
          this.xhr.guide = data.data.guide;
          this.success.guide = true;
        })
        .catch(error => {
          this.xhr.guide = error;
        })
        .finally(() => {
          this.loading.guide = false;
        });
    },

    /**
     * -----------------------------------------
     * post change password hosting
     * -----------------------------------------
     */
    get_upsell() {
      this.xhr_hosting("upsell", this.status.id)
        .then(({ data }) => {
          this.xhr.upsell = data.data.upsell;
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          this.loading.upsell = false;
        });
    },

    /**
     * -----------------------------------------
     * post change password hosting
     * -----------------------------------------
     */
    submitChangePassword(e) {
      e.preventDefault();
      this.$bvModal.hide("modalChangePassword");

      if (
        this.form.changePassword.password_new ===
        this.form.changePassword.password_confirm
      ) {
        //set loading
        this.mx_loading();
        let data = this.form.changePassword;

        ApiService.setHeader();
        ApiService.post(
          this.api.product.hosting.changepassword + "/" + this.status.id,
          data
        )
          .then(() => {
            this.mx_swal("Password has been change", "Success", "success");
            this.form.changePassword.password_new = "";
            this.form.changePassword.password_confirm = "";

            this.get_account();
          })
          .catch(({ response }) => {
            this.mx_swal(response.data.message);
          })
          .finally(() => {
            this.mx_loading(false);
          });
      } else {
        this.mx_swal("Password and confirm password not match");
      }
    },

    /**
     * -----------------------------------------
     * memunculkan bintang dilangit :)
     * -----------------------------------------
     */
    password_sensor(password) {
      let star = "";
      for (let i = 1; i <= password.length; i++) {
        star += "*";
      }
      return star;
    },

    /**
     * --------------------------------------------------------------
     * for copy password
     * --------------------------------------------------------------
     */
    copy_password() {
      if (this.decode_password) {
        copy(this.decode_password);
        this.$bvToast.toast("Password has been copied", {
          title: "Success",
          solid: true,
          variant: "success",
          autoHideDelay: 4000
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * get status avertiz eq, enable, disabled, terminated etc
     * --------------------------------------------------------------
     */
    get_status_avertiz: function() {
      this.http
        .get(this.end.hosting.avertiz_status, this.status.id)
        .then(({ data }) => {
          this.xhr.avertiz = data.data.status;
        })
        .catch(error => {
          this.xhr.avertiz = error.message;
        });
    },

    /**
     * --------------------------------------------------------------
     * check login cpanel atau plesk
     * --------------------------------------------------------------
     */
    login_control_panel: function(module) {
      return {
        name: "rw-redirect",
        params: {
          menu: module === "rwpanel" ? "cpanel" : "plesk-login",
          id: this.xhr.account.id
        }
      };
    }
  },
  computed: {
    /**
     * -----------------------------------------
     * decode base64
     * -----------------------------------------
     */
    decode_password() {
      if (typeof this.xhr.account.post.password !== "undefined") {
        return atob(this.xhr.account.post.password);
      }
      return false;
    }
  },

  mounted() {
    this.get_account();
    this.get_servicestatus();
    this.get_guide();
    this.get_upsell();
  }
};
</script>

<style scoped>
table tr td {
  vertical-align: middle;
  text-align: center;
}

.account-link:hover {
  cursor: pointer;
  color: var(--white);
  background-color: var(--primary);
}

@media (min-width: 576px) {
  #rw-style-hostingapp {
    border-left: 1px solid #dee2e6 !important;
  }
}
</style>
