<template>
  <div>
    <div v-if="loading">
      <div class="row">
        <div class="col-sm-3">
          <div class="card shadow">
            <div class="card-body">
              <v-skeleton-loader type="chip"></v-skeleton-loader> <br />
              <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="card shadow">
            <div class="card-body">
              <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
              <v-skeleton-loader type="sentences@3"></v-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="view" :status="xhr.status" v-else></component>
  </div>
</template>

<script>
import statusActive from "@/views/pages/product/hosting/product-hosting-active.vue";
import statusNonActive from "@/views/pages/product/slot/slotLayananNonActive.vue";
import { productHostingMixin } from "@/common/mixins/product/product-mixin.js";
import { breadcrumb, httpCode, loading } from "@/common/mixins/general.js";

export default {
  name: "productHostingSingle",
  mixins: [breadcrumb, httpCode, loading, productHostingMixin],
  components: { statusActive, statusNonActive },
  props: {
    /**
     * ------------------------------------
     * hosting id get from url path
     * ------------------------------------
     */
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      /**
       * -----------------------------------------
       * tampung data status from req API
       * -----------------------------------------
       */
      xhr: {
        status: {}
      }
    };
  },

  computed: {
    view() {
      return this.xhr.status.status === "Active"
        ? "statusActive"
        : "statusNonActive";
    }
  },

  mounted() {
    /**
     * -----------------------------------------
     * get status hosting
     * -----------------------------------------
     */
    let br = [];
    this.mx_hosting_status(this.id)
      .then(({ data }) => {
        this.xhr.status = data.status;

        if (data.status.domain !== "") {
          br.push({ title: data.status.domain });
        }
      })
      .catch(response => {
        this.xhr.status = response.data;
      })
      .finally(() => {
        this.mx_breadcrumbHosting(br);
        this.loading = false;
      });
  }
};
</script>
